import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import {
  Button,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  // FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/core"
import { handleLogin, isLoggedIn } from "../../services/auth"
import NotyfContext from "../../helpers/NotyfContext"

const Login = props => {
  const { notyf } = useContext(NotyfContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(null)

  if (isLoggedIn()) {
    navigate(`/app/`)
  }

  const handleEmailUpdate = event => {
    setEmail(event.target.value)
  }

  const handlePasswordUpdate = event => {
    setPassword(event.target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    const response = await handleLogin({ email, password })
    console.log(response)

    console.log(notyf)

    if (response.status === "error") {
      setIsLoading(false)
      notyf.error(`Login failed. ${response.error.json.error_description}`)
    } else {
      setIsLoading(false)
      notyf.success(`Success ! You are logged in as ${response.response.email}`)
    }
  }

  return (
    <Flex align="center" justify="center" direction="column" width="100%">
      <Heading>Log in</Heading>
      <form
        method="post"
        onSubmit={event => {
          handleSubmit(event)
        }}
      >
        <FormControl mb="32px" width="100%">
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Input
            type="email"
            id="email"
            aria-describedby="email-helper-text"
            onChange={handleEmailUpdate}
          />
          <FormHelperText id="email-helper-text">
            Address of registered user
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            type="password"
            id="password"
            aria-describedby="password-helper-text"
            onChange={handlePasswordUpdate}
          />
          <FormHelperText id="password-helper-text">
            Password of registered user
          </FormHelperText>
        </FormControl>
        <Button
          variantColor="teal"
          size="lg"
          type="submit"
          isLoading={isLoading}
        >
          Log in
        </Button>
      </form>
    </Flex>
  )
}

export default Login
