import React from "react"

const Details = () => {
  return (
    <div>
      <h2>Ile do oddania tacie: 40186 PLN</h2>
    </div>
  )
}

export default Details
