import React from "react"

const CookingStuff = () => {
  return (
    <div>
      <h2>Cooking stuff</h2>
      <a href="https://www.youtube.com/watch?v=1eL7llVPuJg">
        basic kitchen cooking technics
      </a>
      <a href="https://www.realbuzz.com/articles-interests/nutrition/article-listing/?filter=recipes">
        Nutrition articles fron Realbuzz
      </a>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.realbuzz.com/articles-interests/nutrition/article/7-stop-hunger-foods/"
          >
            7 stop hunger foods
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greatist.com/eat/ultimate-list-kitchen-tools-healthy-cooking"
          >
            https://greatist.com/eat/ultimate-list-kitchen-tools-healthy-cooking
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greatist.com/eat/healthy-breakfast-sandwich-recipes"
          >
            https://greatist.com/eat/healthy-breakfast-sandwich-recipes
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greatist.com/eat/healthy-lunch-ideas-with-3-ingredients"
          >
            simple lunch ideas
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greatist.com/eat/meal-prep-recipes-for-every-meal#1"
          >
            meal prep
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.mydomaine.com/chicken-one-pan-recipes?utm_campaign=mydomaine&utm_medium=email&utm_source=cn_nl&utm_content=19619369&utm_term="
          >
            one pan chicken recipies
          </a>
        </li>
      </ul>
    </div>
  )
}

export default CookingStuff
