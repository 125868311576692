import React, { useState } from "react"
import { Link } from "gatsby"
import { Button } from "@chakra-ui/core"

const Default = () => {
  return (
    <div className="container">
      <h2>
        This is default Route for dynamic part of the page, handled with
        @reach/router
      </h2>
      <div style={{ width: "400px" }}>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
              <img
                src="https://bulma.io/images/placeholders/1280x960.png"
                alt="Placeholder image"
              />
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                    src="https://bulma.io/images/placeholders/96x96.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-4">John Smith</p>
                <p className="subtitle is-6">@johnsmith</p>
              </div>
            </div>

            <div className="content">
              Dziala Bulma scss !<a href="#">#responsive</a>
              <br />
              <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/profile">Profile</Link>
        </Button>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/simple-todo">Simple Todo module</Link>
        </Button>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/shopping-list">Shopping list</Link>
        </Button>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/fitness-stuff">Fitness stuff</Link>
        </Button>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/cooking-stuff">Cooking stuff</Link>
        </Button>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/home-decor-inspiration">
            Home decor inspiration
          </Link>
        </Button>
      </div>
      <div>
        <Button variantColor="teal" variant="outline" margin="12px 0">
          <Link to="/app/protected/nutrition">Nutrition main page</Link>
        </Button>
      </div>
      <div>
        <h2>Bardzo interesująca rzecz:</h2>
        <p>
          Globbing (aka filename generation) List only folders in the current
          directory: ls *(/) List only folders in the current directory and its
          subdirectories: ls **/*(/) Remove all .DS_Store files recursively: rm
          -rf **/.DS_Store. (If you want to be sure which files will be deleted,
          you can press Tab before running the command, and Zsh will expand the
          pattern) There are{" "}
          <a href="http://zsh.sourceforge.net/Doc/Release/Expansion.html#Glob-Qualifiers">
            lots of qualifiers
          </a>{" "}
          you can use to target files with specific attributes. You can enable
          the more complex ones by running setopt extended_glob. Here’s one
          command to recursively match all normal files that have no uppercase
          characters or numbers in the name. They’re executable for the owner
          but not for the rest of the world. The owner must have the UID 1002,
          the file size must be above 30MB, and it must have been modified
          within the last month: ls -l
          **/([^A-Z[:digit:]])##(#q.x^X^u1002Lm+30mM-1) Credits to{" "}
          <a href="https://www.refining-linux.org/archives/37-ZSH-Gem-2-Extended-globbing-and-expansion.html">
            Janek Bevendorff
          </a>
          . You can find other useful tips{" "}
          <a href="https://reasoniamhere.com/2014/01/11/outrageously-useful-tips-to-master-your-z-shell/">
            here
          </a>
          .
        </p>
        <a href="https://medium.com/better-programming/setting-up-your-mac-for-web-development-in-2020-659f5588b883">
          how to set up your mac for devekopment - ultimate guide
        </a>
      </div>
    </div>
  )
}

export default Default
