import React from "react"
import { Link } from "gatsby"

const Confirm = () => {
  return (
    <div>
      <h2>Confirmation successfull</h2>
    </div>
  )
}

export default Confirm
