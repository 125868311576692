import React, { useContext, useState } from "react"
import NotyfContext from "../../helpers/NotyfContext"

const SimpleTodo = () => {
  const { notyf } = useContext(NotyfContext)
  const [title, setTitle] = useState("")
  const [completed, setCompleted] = useState(false)

  const getAllTodos = async () => {
    await fetch("/.netlify/functions/todos-read-all")
      .then(async response => {
        const { statusText, status, ok } = response
        const data = await response.json()

        console.log("asdasd")
        console.log(data)

        if (ok) {
          return { data, status, statusText, ok }
        } else {
          throw { data, status, statusText, ok }
        }
      })
      .then(data => {
        notyf.success(`Success!, ${data.statusText}`)
        return data
      })
      .catch(error => {
        notyf.error(`Failed!, ${error.statusText}`)
        return error
      })
  }

  const testHelloWorld = async () => {
    await fetch("/.netlify/functions/hello-world")
      .then(async response => {
        const { statusText, status, ok } = response
        const data = await response.json()

        if (ok) {
          return { data, status, statusText, ok }
        } else {
          throw { data, status, statusText, ok }
        }
      })
      .then(data => {
        notyf.success(`Success!, ${data.statusText}`)
        return data
      })
      .catch(error => {
        notyf.error(`Failed!, ${error.statusText}`)
        return error
      })
  }

  const addTodoItem = () =>
    createTodo({
      title,
      completed,
    })

  // Function using fetch to POST to our API endpoint
  const createTodo = async data => {
    return await fetch("/.netlify/functions/todos-create", {
      body: JSON.stringify(data),
      method: "POST",
    })
      .then(async response => {
        const { statusText, status, ok } = response
        const data = await response.json()

        if (ok) {
          return { data, status, statusText, ok }
        } else {
          throw { data, status, statusText, ok }
        }
      })
      .then(data => {
        notyf.success(`Success!, ${data.statusText}`)
        return data
      })
      .catch(error => {
        console.log("erro")
        console.log(error)
        notyf.error(`Failed!, ${error.statusText}`)
        return error
      })
  }

  const handleCheckboxChange = event => setCompleted(event.target.checked)
  const handleTitleChange = event => setTitle(event.target.value)

  return (
    <div>
      <h2>Simple todo list: (working directory kinda)</h2>
      {/* here will be map through request from dynamic faunadb */}
      <div>
        <ul>
          <li>Mask app: Ogarnąć widok wyswietlania szpitali</li>
        </ul>
      </div>
      <div>
        <div>
          <label htmlFor="email">title</label>
          <input
            type="text"
            name="title"
            id="title"
            onChange={handleTitleChange}
          />
        </div>
        <div>
          <label htmlFor="email">completed</label>
          <input
            type="checkbox"
            name="completed"
            id="completed"
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      <div>
        <button
          style={{ margin: "4px", backgroundColor: "red", color: "white" }}
          onClick={testHelloWorld}
        >
          test hello world
        </button>
        <button
          style={{ margin: "4px", backgroundColor: "red", color: "white" }}
          onClick={addTodoItem}
        >
          add todo
        </button>
        <button
          style={{ margin: "4px", backgroundColor: "red", color: "white" }}
          onClick={getAllTodos}
        >
          get all todos
        </button>
      </div>
    </div>
  )
}

export default SimpleTodo
