import React from "react"
import { Notyf } from "notyf"

const defaultContextValue =
  typeof document !== "undefined"
    ? {
        notyf: new Notyf({
          duration: 5000, // Set your global Notyf configuration here
          ripple: true,
          position: { x: "right", y: "bottom" },
          dismissible: true,
        }),
      }
    : {}
const NotyfContext = React.createContext(defaultContextValue)
const NotyfContextProvider = props => {
  return <NotyfContext.Provider>{props.children}</NotyfContext.Provider>
}
export { NotyfContext as default, NotyfContextProvider }
