import React from "react"
import { getJWTToken } from "../../services/auth"

const Profile = () => {
  const checkIdentity = () => {
    const jwt = getJWTToken()
    fetch("/.netlify/functions/auth-hello", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt, // like this
      },
    })
      .then(res => {
        console.log("response from auth function: ")
        return res.json()
      })
      .then(res => {
        console.log("promised resolved: ")
        console.log(res)
      })
      .catch(err => {
        console.log("error: ")
        console.log(err)
      })
  }

  return (
    <>
      <h1>Your profile</h1>
      <ul>
        <li>Logged in token: {getJWTToken()}</li>
      </ul>
      <div>
        <button onClick={checkIdentity}>check identity</button>
      </div>
    </>
  )
}
export default Profile
