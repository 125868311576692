import React from "react"

const NutritionIndex = () => {
  return (
    <div>
      <h2>This is Nutrition index page.</h2>
      <div>
        <h3>Shopping advices and rules</h3>
        <h4>Canned goods</h4>
        <ul>
          <li>Tomatoes</li>
          <li>Tuna</li>
          <li>Sweetcorn</li>
          <li>Beans</li>
        </ul>

        <h4>
          Cupboard staples - a lot of different stuff that i can store in
          cupboards
        </h4>
        <ul>
          <li>Herbs and spices</li>
          <li>Couscous</li>
          <li>Porridge</li>
          <li>Unsalted nuts</li>
          <li>Dried fruit</li>
        </ul>

        <h4>Frozen goods</h4>
        <ul>
          <li>Vegetables</li>
        </ul>

        <h4>Handy foods to have</h4>
        <ul>
          <li>Lemons - vitamin c</li>
          <li>Onions</li>
          <li>Garlic</li>
        </ul>

        <h4>Onec a week, stock up on</h4>
        <ul>
          <li>Fresh fruit and vegetables</li>
          <li>Lean meat for protein, iron and zinc</li>
          <li>Oily fish (omega 3), example: salmon</li>
          <li>
            Low fat dairy products - greek yoghurt, normal yoghurt (calcium)
          </li>
        </ul>
      </div>

      <h3>
        Here will be a lot of stuff, related to my overal nutrition strategy.
      </h3>
      <div>
        <a href="https://greatist.com/health/healthy-fast-breakfast-recipes?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-04-02&apid=#smoothies">
          great breakfast ideas
        </a>
        <a href="https://heated.medium.com/11-things-to-add-to-scrambled-eggs-edc0e4b2ca36">
          11 things you can do with scrambled eggs
        </a>
        <a href="https://www.realbuzz.com/articles-interests/health/article/10-ways-to-stay-healthy-on-a-budget/">
          10 ways to stay healthy on a budget
        </a>
        <a href="https://www.thekitchn.com/our-ultimate-guide-to-making-the-best-pizza-ever-221169">
          how to make the best pizza ever
        </a>
        <a href="https://greatist.com/eat/bean-dinner-party-recipes?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-20&apid=">
          6 Bean dishes
        </a>
        <a href="https://www.realbuzz.com/articles-interests/health/article/five-best-snacks-to-eat-at-your-desk/">
          5 best snacks to eat at your desk
        </a>
        <a href="https://greatist.com/health/filling-foods?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-26&apid=#2.-Full-fat-Greek-yogurt">
          20 most filling food
        </a>
        <a href="https://greatist.com/eat/green-smoothie-recipes?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-02-28&apid=#1">
          green smoothie recipes
        </a>
        <a href="https://greatist.com/health/35-quick-and-healthy-low-calorie-lunches?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-26&apid=">
          35 quick and healthy low calorie lunches
        </a>
        <a href="https://greatist.com/health/greek-yogurt-recipes-healthy-delicious?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-26&apid=#chicken">
          greek yoghurt recipes
        </a>
      </div>
      <div>
        <h4>some nice tips</h4>
        <ol className="q-box">
          <li>
            <span>Intermittently fast. </span>
            <span>
              This is not only a lifestyle diet to help you shed body fat, but
              it’s also a great method to increase your longevity, quality of
              life and energy, whilst reducing inflammation and toxicity. You
              will lose body weight fast, and even more effectively when coupled
              with exercise
            </span>
          </li>
          <li>
            <span>Weight train.</span>
            <span>
              {" "}
              It’s so important to lift weights, both heavy and moderate, in
              order to increase muscle mass, tone, conditioning, as well as for
              weight loss. Nothing is more effective than building your bodies
              strength and bone density, for quality of life beyond your current
              age bracket. Embrace the ability to build muscle, as you are also
              increasing your metabolisms efficiency.
            </span>
          </li>
          <li>
            <span>Eat properly. </span>
            <span>
              This means whole foods, nourishing sources of protein and the
              right form of carbohydrates. There is no point at all depriving
              yourself of natural and healthy foods. Structure your meals to
              contain the highest amounts of raw vegetables, followed by
              protein, according to your bodies weight. The smallest part of
              your plate is saved for carbs, that will be dependent on your
              level of training. Fill your belly with fresh vegetables and
              moderate amounts of protein. These are the key components to a
              healthy body, and shapely physique.
            </span>
          </li>
          <li>
            <span>Say no to added sugar.</span>
            <span>
              {" "}
              Sugar is added in almost all items located within supermarket
              isles. Stay away from packed sauces, mixes and even your protein
              bars! Check every label, and make sure the sugar content is zero
              or extremely low. Manufacturers hide sugar in forms of ‘so called
              healthy’ alternatives like maple syrup, coconut sugar and the
              like. Now, although these are a lot better when comparing them to
              white sugar, it’s still a sweetened alternative. You do not need
              to consume these. In moderation yes, but daily no.
            </span>
          </li>
          <li>
            <span>Eat more fiber. </span>
            <span>
              Soluble fibre absorbs water and slows down the passing of food
              through the digestive tract. This can delay stomach emptying,
              making it expand and feel a lot more full than it is. This means,
              you may consume a lot less calories, because you can’t really eat
              when you are full! This is great news if you are trying to aid
              yourself of those hunger pangs.
              <br />
              The great news is that soluble fiber decreases the accumulation of
              fat around the organs, reducing the size of your waistline.
              <br />
              The best sources of fibre include avocado, brussels sprouts,
              blackberries, flaxseeds and oats. Try and eat as much of these as
              you can on a daily basis.
            </span>
          </li>
          <li>
            <span>HIIT interval training. </span>
            <span>
              To get into great shape, and lose those stubborn pockets of body
              fat, try HIIT. The trick is to go all out when you hit the high
              intensity period, then rest for a short amount of time, before
              starting this again all over. It will leave you breathless, but
              give you the most amazing results. This form of high intensity
              cardio should only be implemented for 20 minutes, and at best 2–3
              times per week. Save your strength and drive for weight training
              instead.
            </span>
          </li>
          <li>
            <span>Add the magic of apple cider.</span>
            <span>
              {" "}
              Acetic acid is one of the main components of apple cider, which
              has been known to reduce fat accumulation. Always pick the mother
              apple acids, which is always cloudy on the bottom of the glass.
              Add a shot of apple cider in the morning, upon waking, then during
              the day and in salads. It’s sour, tasty and absolutely delicious.
            </span>
          </li>
          <li>
            <span>Do lots of incidental exercise, like walking</span>
            <span>
              . You don’t need to exercise like a maniac every day to get in
              shape. Walking has some great benefits. Brisk walks even help you
              to reduce your overall body fat, especially around the belly area.
              All you need to do is walk for 30–40 minutes extra per day. Aim to
              do an hour or so when you are free. Building up to longer sessions
              is always advisable.
            </span>
          </li>
          <li>
            <span>Drink lots of water. I</span>
            <span>
              {" "}
              aim to drink 3–4 litres per day. One before working out, one
              during my workout, and the rest during the later part of the day.
              This does not include beverages. I usually have a couple of
              coffees, and many glasses of herbal tea. Keep yourself hydrated,
              not only for functioning optimally, but for also nourishing your
              body and being able to train effectively in the gym. Sugar filled
              drinks are out of the picture. Just focus on easy access to fresh,
              cold water and some warm herbal beverages.
            </span>
          </li>
          <li>
            <span>Chew your food properly. </span>
            <span>
              This is a big one for many people. I know you are starving, but
              your body needs you to chew properly, so the food can be properly
              digested and travel along your insides, to nourish you correctly.
              Fast and furious eating promotes gas, bloating, irritability, and
              other awful symptoms. Sit down, and slowly chew your food,
              enjoying the taste, sound and texture. No eating on the run here.
              Stop, smell the roses, and enjoy. :)
            </span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default NutritionIndex
