import React from "react"

const FitnessIndex = () => {
  return (
    <div>
      <h1>Fitness Index</h1>
      <div>
        <h2>List of fitness exercises</h2>
        <ul>
          <li>
            https://www.realbuzz.com/articles-interests/fitness/article/7-exercises-for-a-flat-stomach/
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FitnessIndex
