import React from "react"

const ShoppingList = () => {
  return (
    <div>
      <h2>Shopping list</h2>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.realbuzz.com/articles-interests/nutrition/article/11-foods-that-can-help-you-lose-weight/"
      >
        good stuff to always have
      </a>
    </div>
  )
}

export default ShoppingList
