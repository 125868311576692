import React from 'react';

const HomeDecorInspiration = () => {
    return (
        <div>
            <h2>Home decor inspiration</h2>
            <a href="https://demiandashton.org/towel-storage-bathroom/">Towel storage</a>
        </div>
    )
}

export default HomeDecorInspiration;