import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/PrivateRoute"
import Profile from "../components/app/Profile"
import Details from "../components/app/Details"
import ShoppingList from "../components/app/ShoppingList"
import HomeDecorInspiration from "../components/app/HomeDecorInspiration"
import FitnessStuff from "../components/app/FitnessStuff"
import CookingStuff from "../components/app/CookingStuff"
import Default from "../components/app/Default"
import Confirm from "../components/app/Confirm"
import Login from "../components/app/Login"
import NutritionIndex from "../components/app/Nutrition"
import FitnessIndex from "../components/app/Fitness"
import SimpleTodo from "../components/app/SimpleTodo"

// This file is a beggining of dynamic React, where @reach/router handles every
// /app/ route.
const App = () => (
  <div>
    <Router basepath="/app">
      <PrivateRoute path="/protected/profile" component={Profile} />
      <PrivateRoute path="/protected/details" component={Details} />
      <PrivateRoute path="/protected/shopping-list" component={ShoppingList} />
      <PrivateRoute path="/protected/fitness-stuff" component={FitnessStuff} />
      <PrivateRoute path="/protected/cooking-stuff" component={CookingStuff} />
      <PrivateRoute
        path="/protected/home-decor-inspiration"
        component={HomeDecorInspiration}
      />
      <PrivateRoute path="/protected/nutrition" component={NutritionIndex} />
      <PrivateRoute path="/protected/fitness" component={FitnessIndex} />
      <PrivateRoute path="/protected/simple-todo" component={SimpleTodo} />
      <Login path="/login" />
      <Confirm path="/confirm" />
      <Default path="/" />
    </Router>
  </div>
)

export default App
