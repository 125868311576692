import React from "react"

const FitnessStuff = () => {
  return (
    <div>
      <h2>Fitness stuff</h2>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.realbuzz.com/articles-interests/fitness/article/the-4-minute-flat-stomach-workout/"
          >
            4 minute flat stomach workout
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.realbuzz.com/articles-interests/fitness/article/how-to-plan-the-perfect-workout/"
          >
            how to plan perfect workout
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.realbuzz.com/articles-interests/fitness/article/total-fitness-in-just-three-hours-per-week/"
          >
            https://www.realbuzz.com/articles-interests/fitness/article/total-fitness-in-just-three-hours-per-week/
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greatist.com/move/morning-workout-hacks?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-10&apid="
          >
            fitness tips how to wake up -
            https://greatist.com/move/morning-workout-hacks?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-10&apid=
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://greatist.com/move/abs-workout-best-abs-exercises-you-can-do-standing-up?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-02-27&apid="
          >
            standing ABS training -
            https://greatist.com/move/abs-workout-best-abs-exercises-you-can-do-standing-up?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-02-27&apid=
          </a>
        </li>
        <li>
          <a href="https://greatist.com/move/best-free-workout-videos-youtube?utm_source=Sailthru%20Email&utm_medium=Email&utm_campaign=daily&utm_content=2020-03-26&apid=#equipment-free">
            some workout videos
          </a>
        </li>
      </ul>
    </div>
  )
}

export default FitnessStuff
